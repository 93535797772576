import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookies from 'js-cookie';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import Router from 'next/router';
import { DOMAIN, ROUTES } from '@gtintel/platform-constants';
import { setCookie } from '@gtintel/platform-utils';
import axios from './axios_instance';
import * as PATHS from './paths';
const debouncedReloadRoute = debounce(Router.reload, 700);
export function setAuthorizationHeader(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}
const param = {
  refresh: Cookies.get('refresh_token')
};
if (Cookies.get('token')) {
  setAuthorizationHeader(Cookies.get('token'));
}
const refreshAuthLogic = failedRequest => {
  if (!param.refresh) {
    return Promise.resolve(() => {
      Router.push(ROUTES.LOGIN_PAGE).catch(() => {});
    });
  }
  if (failedRequest.config.url !== PATHS.LOGIN) {
    return axios.post(DOMAIN + 'token/refresh/', {
      ...param,
      skipAuthRefresh: true
    }).then(tokenRefreshResponse => {
      const accessToken = get(tokenRefreshResponse, ['data', 'access'], '');
      setAuthorizationHeader(accessToken);
      setCookie('token', accessToken);
      debouncedReloadRoute();
    });
  }
  return Promise.resolve(debouncedReloadRoute);
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic);
const valueOrEmptyQueryString = (query, value, prefix = '&') => value ? `${prefix}${query}=${value}` : '';
export const auth = {
  login: data => axios.post(PATHS.LOGIN, data),
  loginWithVerifyCode: data => axios.post('2fa/token-verify/', data),
  getInforUser: email => axios.get(`${PATHS.GET_USER_BY_EMAIL + email}&is_unique=false`),
  set_new_password: data => axios.put(PATHS.SET_NEW_PASSWORD, data),
  resendActivationEmail: data => axios.put(PATHS.RESEND_ACTIVATION_EMAIL, data),
  passwordReset: data => axios.put(PATHS.RESET_PASSWORD, data),
  logOut: () => axios.post(`${PATHS.API_LOGOUT}`),
  getUserByEmail: email => axios.get(`${PATHS.GET_USER_BY_EMAIL_V3 + encodeURIComponent(email)}`)
};
export const batchRequests = {
  list: data => axios.get(`${PATHS.GET_BATCH_REQUESTS}?limit=${data.limit}&offset=${data.offset}&search=${data.search}&sort=${data.sort}`),
  getBatchDetail: id => axios.get(`${PATHS.GET_BATCH_DETAIL}${id}/`),
  getBatchDetails: data => axios.get(`${PATHS.GET_BATCH_DETAIL}${data.id}/?limit=${data.limit}&offset=${data.offset}&prosearch=${data.prosearch}&sort=${data.sort}`),
  deleteProjects: data => axios.post(`v2/delete_multi/`, data),
  matchProjects: data => axios.post(`${PATHS.MATCH_PROJECT}`, data),
  RematchProject: data => axios.post(`${PATHS.REMATCH_PROJECT}`, data),
  getProjectsProposals: id => axios.get(`${PATHS.GET_PARTNER_PROPOSAL}?project_id=${id}&accept_batch=1`)
};
export const project = {
  list_source: data => axios.get(`v2/${PATHS.PROJECTS}`, {
    params: {
      client_name: data.client_name,
      cx_lead: data.cx_lead,
      limit: data.limit,
      offset: data.offset,
      parent_id: data.parent_id,
      partner_name: data.partner_name,
      project_name: data.project_name,
      requested_completion_date: data.requested_completion_date,
      sort: data.sort,
      source: data.source,
      start_date: data.start_date,
      status: data.status,
      tag: data.tag,
      team_name: data.team_name,
      include_dev: data.include_dev
    }
  }),
  list: data => axios.get(`v2/${PATHS.PROJECTS}dashboard/`, {
    params: {
      limit: data.limit,
      offset: data.offset,
      client_name: data.client_name,
      partner_name: data.partner_name,
      project_name: data.project_name,
      cx_lead: data.cx_lead,
      tag: data.tag,
      start_date: data.start_date,
      requested_completion_date: data.requested_completion_date,
      created_date: data.created_date,
      status: data.status,
      sort: data.sort
    }
  }),
  options: params => axios.get(`v2/${PATHS.PROJECTS}options?exclude=${params.exclude}`),
  getProjectListForChat: data => axios.post(`v2/projects_chat/`, data),
  getProjectDetail: id => axios.get(`v2/${PATHS.PROJECTS}${id}`),
  getProjectDetailNew: id => axios.get(`v2/${PATHS.PROJECTS}${id}/`),
  getProjectPartner: projectId => axios.get(`v2/projects/${projectId}/partner/`),
  project_activity: (id, data) => axios.get(`v2/projects/activities/?project_id=${id}&limit=${data.limit}&offset=${data.offset}`),
  project_reports: projectId => axios.get(`v2/projects/reports/?project_id=${projectId}`),
  type: () => axios.get('v2/projects/types/'),
  updateProject: (id, _idType, data) => axios.put(`${DOMAIN}v2/projects/${id}`, data),
  createProject: (_idType, data) => axios.post(`${DOMAIN}v2/projects/create/`, data),
  deleteProject: id => axios.delete(`${DOMAIN}v2/projects/${id}`),
  updateProjectDescription: (id, data) => axios.put(`v2/projects/description_update/${id}`, data),
  createReport: data => axios.post(`v2/projects/reports/`, data),
  updateReport: (data, id) => axios.put(`v2/projects/reports/${id}`, data),
  reportDownloadNotification: id => axios.post(`v2/projects/reports/${id}/download_notification`),
  getReport: id => axios.get(`v2/projects/reports/${id}/`),
  uploadFile: value => axios.post(PATHS.UPLOAD_FILE, value),
  changeStatus: (id, data) => axios.put(`${DOMAIN}v2/projects/${id}`, data),
  getPartnerProposal: (id, search) => axios.get(`${PATHS.GET_PARTNER_PROPOSAL}?project_id=${id}&q=${encodeURIComponent(search)}`),
  getProjectsProposal: id => axios.get(`v2/projects/${id}/proposals/`),
  projectsProposal: data => axios.post(`v2/projects/${data.project_id}/proposals/${data.user_id}`),
  acceptProposal: data => axios.patch(`v2/projects/${data.project_id}/proposals/${data.user_id}/accept`),
  declineProposal: data => axios.patch(`v2/projects/${data.project_id}/proposals/${data.user_id}/decline`),
  proposalAction: data => axios.patch(`v2/projects/${data.project_id}/proposals/${data.user_id}/${data.action}`),
  clientDeclined: data => axios.post(`${PATHS.CLIENT_DECLINED}`, data),
  adminUnmatch: data => axios.post(`${PATHS.UNMATCH_ADMIN}`, data),
  switchProjectStatus: (data, da) => axios.patch(`${PATHS.SWITCH_PROJECT_STATUS}${data}/`, da),
  projectReportsAccept: data => axios.post(`${PATHS.PROJECT_REPORTS + data.project_id}/accept/`, data),
  deleteMemberFromProject: (projectId, memberId) => axios.delete(`v2/projects/${projectId}/members/${memberId}`),
  addMemberToProject: (data, projectId) => axios.put(`v2/projects/${projectId}/members/`, data),
  getPartnerStatus: id => axios.get(`${PATHS.GET_PARTNER_STATUS}${id}`),
  getProjectDescription: (data, projectId) => {
    const params = [];
    if (data?.limit) params.push('limit=' + data.limit);
    if (data?.offset) params.push('offset=' + data.offset);
    const paramString = params.length > 0 ? `?${params.join('&')}` : '';
    return axios.get(`v2/projects/description/${projectId}${paramString}`);
  },
  switchMuteStateProject: (projectId, muted) => axios.put(`v2/projects/${projectId}/mute-notifications`, {
    is_muted: muted
  }),
  uploadDocument: data => axios.post(`v3/document-library/`, data),
  uploadMultipleDocuments: data => axios.post('v3/document-library/files/', data),
  listDocuments: (id, path) => axios.get(`v3/document-library/?project_id=${id}&filter_directory=${path}`),
  removeDocument: id => axios.delete(`/v3/document-library/${id}`),
  removeFolder: data => axios.delete(`/v3/document-library/delete-folder/`, data),
  addDocumentLibraryFilePermission: (data, fileId) => axios.post(`/v3/document-library/permissions/${fileId}`, data),
  updateDocumentLibraryFilePermission: (data, fileId) => axios.put(`/v3/document-library/permissions/${fileId}`, data),
  removeDocumentLibraryFilePermission: (data, fileId) => axios.delete(`/v3/document-library/permissions/${fileId}`, data),
  updateDocumentLibraryFilePermissionByFolder: data => axios.put(`/v3/document-library/permissions/permission-by-folder`, data),
  removeDocumentLibraryFilePermissionByFolder: data => axios.delete(`/v3/document-library/permissions/permission-by-folder`, data),
  moveFolder: data => axios.put(`v3/document-library/move-folder-and-files`, data),
  downloadDocumentLibraryFolder: data => axios.get(`/v3/document-library/download-folders/?filter_directory=${data.folder}&project_id=${data.projectId}`, data),
  getSubject: id => axios.get(`v2/projects/subjects/${id}/`),
  deleteSubject: id => axios.delete(`v2/projects/subjects/${id}/`),
  updateSubject: (id, data) => axios.put(`v2/projects/subjects/${id}/`, data),
  createProjectSubject: (projectId, data) => axios.post(`v2/projects/${projectId}/subjects/`, data),
  listProjectSubjects: projectId => axios.get(`v2/projects/${projectId}/subjects/`),
  reorderProjectSubjects: (projectId, {
    order
  }) => axios.patch(`v2/projects/${projectId}/subjects/reorder`, {
    order
  }),
  getTemplateLibrary: options => axios.get(`/projects/library`, {
    signal: options?.signal
  }),
  createTemplate: payload => axios.post(`projects/templates`, payload),
  updateTemplate: (id, payload) => axios.patch(`projects/templates/${id}`, payload),
  deleteTemplate: id => axios.delete(`projects/templates/${id}`),
  copyTemplate: id => axios.post(`projects/templates/${id}/copy`),
  createTemplateScope: (templateId, payload) => axios.post(`projects/templates/${templateId}/scope_components`, payload),
  updateScope: (id, payload) => axios.patch(`projects/scope_components/${id}`, payload),
  deleteScope: id => axios.delete(`projects/scope_components/${id}`),
  copyScope: id => axios.post(`projects/scope_components/${id}/copy`),
  getProjectScope: id => axios.get(`projects/${id}/scope`),
  getProjectBuilderState: id => axios.get(`v5/projects/${id}/state/`),
  importProjectFromTemplate: (id, importFromTemplate) => axios.patch(`projects/${id}/template`, importFromTemplate),
  createProjectScope: (projectId, scope) => axios.post(`projects/${projectId}/scope`, scope),
  createSubProject: projectId => axios.post(`projects/${projectId}/subproject/create/`),
  importScopesFromXlsx: (templateId, data) => axios.post(`projects/scope_components/template/${templateId}/import`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  importTemplatesFromXlsx: data => axios.post(`projects/templates/import`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  updateProjectComponentsOrder: (projectId, order) => axios.patch(`projects/${projectId}/scope_components/reorder`, {
    order
  }),
  updateTemplateComponentsOrder: (templateId, order) => axios.patch(`projects/templates/${templateId}/scope_components/reorder`, {
    order
  }),
  updateProject2: (id, changes) => axios.patch(`projects/${id}/update`, changes),
  addAttachmentToProject: (projectId, data) => axios.post(`projects/${projectId}/attachment`, data),
  updateProjectV5: (id, changes) => axios.patch(`v5/projects/${id}/`, changes),
  updateProjectFlagsV5: (id, changes) => axios.patch(`v5/projects/${id}/flags`, changes),
  createFlags: projectId => axios.post(`v5/projects/${projectId}/flags/create`),
  clearScopes: projectId => axios.delete(`projects/${projectId}/scope_components/delete/`),
  createNPRequest: projectId => axios.post(`projects/${projectId}/requests`, {
    project: projectId
  }),
  searchPartners: q => axios.get(`projects/requests/partners`, {
    params: {
      search: q
    }
  }),
  changeNPRequest: (id, changes) => axios.patch(`projects/requests/${id}/change`, changes),
  listNPRequestScopes: () => axios.get(`projects/requests/scope`),
  getProjectBuilderInitialState: () => axios.get(`v5/projects/state/`),
  createProjectV5: data => axios.post(`v5/projects/`, data),
  addDocumentToProject: (projectId, data) => axios.post(`v5/projects/${projectId}/documents/`, data),
  listNPRequests: () => axios.get('projects/requests'),
  editNPRequest: (id, data) => axios.patch(`projects/requests/${id}`, data),
  acceptNPRequest: id => axios.post(`projects/requests/${id}/accept`),
  declineNPRequest: id => axios.post(`projects/requests/${id}/decline`),
  cancelNPRequest: id => axios.post(`projects/requests/${id}/cancel`),
  sendNPRequest: id => axios.post(`projects/requests/${id}/send`),
  assignNPRequest: id => axios.post(`projects/requests/${id}/assign`),
  finishedNPRequest: id => axios.post(`projects/requests/${id}/finish`),
  copyNPRequest: id => axios.post(`projects/requests/${id}/copy`),
  submitNPRequest: id => axios.post(`projects/requests/${id}/submit`),
  unsubmitNPRequest: id => axios.post(`projects/requests/${id}/unsubmit`),
  deleteNPRequest: id => axios.delete(`projects/requests/${id}`),
  updateFragment: (id, unsafe_body) => axios.patch(`v5/projects/report_fragment/${id}/`, {
    unsafe_body
  }),
  projectAssignments: () => axios.get('projects/assignments'),
  listDocumentsV2: id => axios.get(`v5/projects/${id}/documents/`),
  listAssignmentDocuments: id => axios.get(`projects/requests/${id}/documents/`),
  createAssignmentDocument: (id, data) => axios.post(`projects/requests/${id}/documents/`, data),
  deleteAssignmentDocument: (id, attachmentId) => axios.delete(`projects/requests/${id}/documents/${attachmentId}/`)
};
export const attachment = {
  getPresignedUrl: data => axios.post(`projects/attachment/get_presigned_url`, data),
  delete: attachmentId => {
    return axios.delete(`projects/attachment/${attachmentId}`);
  },
  addToSubject: (subjectId, data) => {
    return axios.post(`v5/projects/subjects/${subjectId}/attachment/`, data);
  }
};
export const template = {
  addAttachmentToTemplate: (templateId, data) => {
    return axios.post(`projects/templates/${templateId}/attachment`, data);
  }
};
export const tags = {
  listTag: data => axios.get(`${PATHS.TAGS}?limit=${data.limit}&offset=${data.offset}&q=${encodeURIComponent(data.search)}`),
  listTagInGroup: data => axios.post(`${PATHS.TAGS}search?limit=${data.limit}&offset=${data.offset}`, {
    tag_group_id: String(data.tag_group_id)
  }),
  getTagGroupById: categoryId => axios.get(`${PATHS.CATEGORIES}${categoryId}`),
  getTagGroups: mode => axios.get(`/${PATHS.CATEGORIES}?mode=${mode}`),
  getTagTypeOptions: params => axios.get(`/${PATHS.CATEGORIES}${params}`)
};
export const category = {
  list: () => axios.get(`${PATHS.CATEGORIES}`),
  create: data => axios.post(`${PATHS.CATEGORIES}`, data),
  createTagToTagGroup: data => axios.post(`${PATHS.TAGS}`, data),
  editTagInTagGroup: (tagId, data) => axios.put(`${PATHS.TAGS}${tagId}/`, data),
  deleteTag: id => axios.delete(`${PATHS.TAGS}${id}/`)
};
export const user = {
  list: data => {
    let params = `limit=${data.limit}&offset=${data.offset ?? 0}&is_unique=1`;
    if (data.search) params += `&q=${encodeURIComponent(data.search)}`;
    if (data.tags) params += `&tags=${data.tags}`;
    return axios.get(`v2/${PATHS.USERS}?${params}`);
  },
  listAdmin: () => axios.get(`v2/${PATHS.USERS}?limit=999&is_admin=1`),
  createUser: data => axios.post(PATHS.USERS, data),
  detailUser: id => axios.get(`v2/${PATHS.USERS + id}/`),
  updateUser: data => axios.patch(`v2/${PATHS.USERS + data.user_id}/`, data),
  getLatestReportsUser: data => axios.get(`${PATHS.USER_GET_LATEST_REPORTS}/?user_id=${data}`),
  updateNotificationsStatus: data => axios.put(`${PATHS.USERS}update_mute_status/`, data),
  getNotificationsStatus: () => axios.get(`${PATHS.USERS}get_mute_status`),
  get_partner_chat: () => axios.get(`${PATHS.GET_PARTNER_CHAT}`),
  get_client_chat: () => axios.get(`${PATHS.GET_CLIENT_CHAT}`),
  createApiKey: id => axios.put(`${PATHS.USERS}${id}/apikey/`),
  getApiKey: id => axios.get(`${PATHS.USERS}${id}/apikey/`),
  uploadAvatar: formData => axios.patch(`${PATHS.UPLOAD_USER_AVATAR}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
};
export const twilio_chat = {
  token: () => axios.get(PATHS.GET_TWILIO_TOKEN),
  getLatestReportsUser: data => axios.get(`${PATHS.USER_GET_LATEST_REPORTS}?user_id=${data}`),
  getPendingPartnerProposal: projectId => axios.get(`${PATHS.USERS}get_pending_partner_proposal/?project_id=${projectId}`),
  getAcceptedPartnerProposal: data => axios.get(`${PATHS.USERS}get_accepted_partner_proposal/?project_id=${data}`),
  inviteUser: data => axios.post(PATHS.INVITE_CHAT_USER, data),
  createExtraProjectChannel: data => axios.post(PATHS.CREATE_EXTRA_PROJECT_CHANNEL, data),
  addAdminToChat: data => axios.post(PATHS.ADD_ADMIN_TO_CHAT, data),
  removeAdminFromChat: data => axios.post(PATHS.REMOVE_ADMIN_FROM_CHAT, data),
  joinChannel: data => axios.post(PATHS.JOIN_CHANNEL, data)
};
export const teams = {
  list: data => axios.get(`${PATHS.TEAMS}${valueOrEmptyQueryString('limit', data.limit, '?')}${valueOrEmptyQueryString('offset', data.offset)}${valueOrEmptyQueryString('q', data.search)}`),
  listSimplified: data => axios.get(`${PATHS.TEAMS}simplified/${valueOrEmptyQueryString('limit', data.limit, '?')}${valueOrEmptyQueryString('offset', data.offset)}${valueOrEmptyQueryString('q', data.search)}`),
  getTeammatesNotOnProject: projectId => axios.get(`${PATHS.TEAMS}teammates_not_on_project/${projectId}`),
  getOne: teamId => axios.get(`${PATHS.TEAMS}${teamId}`),
  create: data => axios.post(`${PATHS.TEAMS}`, data),
  update: (teamId, data) => axios.put(`${PATHS.TEAMS}${teamId}`, data)
};
export const organizations = {
  list: data => {
    const params = [];
    if (data?.limit) params.push('limit=' + data.limit);
    if (data?.offset) params.push('offset=' + data.offset);
    if (data?.search) params.push('q=' + encodeURIComponent(data.search));
    return axios.get('organizations?' + params.join('&'));
  },
  getOne: id => axios.get(`organizations/${id}`),
  getTeams: id => axios.get(`organization_teams/${id}`)
};
export const firebase = {
  deleteAllNotifications: user_id => axios.delete(`v2/firebase/clear/${user_id}`),
  getToken: () => axios.get(PATHS.GET_FIREBASE_TOKENS)
};
export const scopeBuilder = {
  get: id => axios.get(`${PATHS.PROJECT_SCOPE}${id}`),
  create: data => axios.post(`${PATHS.PROJECT_SCOPE}create_scope/`, data),
  update: data => axios.put(`${PATHS.PROJECT_SCOPE}update/${data.id}`, data),
  delete: id => axios.delete(`${PATHS.PROJECT_SCOPE}delete/${id}`),
  getDraftList: data => axios.get(PATHS.PROJECT_SCOPE, {
    params: {
      limit: data.limit,
      offset: data.offset,
      client_name: data.client_name,
      project_name: data.project_name,
      tag: data.tag,
      start_date: data.start_date,
      requested_completion_date: data.requested_completion_date,
      created_date: data.created_date,
      sort: data.sort
    }
  }),
  getTagList: tags => axios.post(PATHS.GET_TAG_LIST, tags),
  createProject: id => axios.post(`${PATHS.PROJECT_SCOPE}submit/${id}`)
};
export const thirdPartyChecks = {
  getState: projectId => axios.get(`/projects/${projectId}/third_party_checks/state/`),
  runProjectChecks: (projectId, checks) => {
    const data = {};
    if (checks) {
      data['checks'] = checks;
    }
    return axios.post(`/projects/${projectId}/third_party_checks/project_checks/`, data);
  },
  runSubjectChecks: (projectId, subjectId, aliasId, checks) => {
    const data = {
      subject_id: subjectId
    };
    if (aliasId) {
      data['alias_id'] = aliasId;
    }
    if (checks) {
      data['checks'] = checks;
    }
    return axios.post(`projects/${projectId}/third_party_checks/subject_checks/`, data);
  },
  getNewResponses: (projectId, requestIds) => axios.get(`/projects/${projectId}/third_party_checks/new_responses/`, {
    params: {
      request_ids: requestIds.join(',')
    }
  }),
  createOrUpdateMatch: (projectId, thirdPartyCheckResultUUID, data) => axios.post(`/projects/${projectId}/third_party_checks/results/${thirdPartyCheckResultUUID}/match/`, data),
  summarise: (projectId, resultIds) => axios.post(`/projects/${projectId}/third_party_checks/summarise/`, {
    result_ids: resultIds
  }),
  thirdPartyChecksExist: projectId => axios.head(`/projects/${projectId}/third_party_checks/`),
  thirdPartyChecksExistForAlias: (projectId, subjectAliasId) => axios.head(`/projects/${projectId}/third_party_checks/?subject_alias_id=${subjectAliasId}`),
  thirdPartyChecksExistForSubject: (projectId, subjectId) => axios.head(`/projects/${projectId}/third_party_checks/?subject_id=${subjectId}`),
  getThirdPartyChecks: projectId => axios.get(`/projects/${projectId}/third_party_checks/`),
  getThirdPartyChecksByRequestId: (projectId, requestId) => axios.get(`/projects/${projectId}/third_party_checks/?request_id=${requestId}`),
  runThirdPartyChecks: (projectId, additionalParams = '') => axios.post(`/projects/${projectId}/third_party_checks/${additionalParams}`),
  markAllAsFalsePositive: (projectId, subjectId, subjectAliasId, typeOfCheck) => axios.post(`/projects/${projectId}/third_party_checks/results/all_false_positive/`, {
    subject_id: subjectId,
    subject_alias_id: subjectAliasId,
    type_of_check: typeOfCheck
  }),
  createSummarisation: (projectId, matchType, typeOfCheck, thirdPartyCheckRequestId, systemPrompt, maxTokens, temperature) => {
    const data = {
      match_type: matchType,
      third_party_check_request: thirdPartyCheckRequestId,
      type_of_check: typeOfCheck
    };
    if (maxTokens) data['max_tokens'] = maxTokens;
    if (temperature) data['temperature'] = temperature;
    if (systemPrompt) data['system_prompt'] = systemPrompt;
    return axios.post(`/projects/${projectId}/third_party_checks/summary/`, data);
  },
  createSummarisationConfig: data => axios.post(`/third_party_checks/summary/config/`, data),
  getSummarisationConfig: () => axios.get(`/third_party_checks/summary/config/`)
};
export const scopeService = {
  createDueDiligence: data => axios.post(`${PATHS.SCOPE_SERVICE}create_due_diligence/`, data),
  updateDueDiligence: data => axios.put(`${PATHS.SCOPE_SERVICE}update_due_diligence/${data.id}`, data),
  deleteDueDiligence: id => axios.delete(`${PATHS.SCOPE_SERVICE}delete_due_diligence/${id}`),
  getAllScopeDueDiligence: scopeId => axios.get(`${PATHS.SCOPE_SERVICE}get_due_diligence_by_id/${scopeId}`),
  createSiteVisit: data => axios.post(`${PATHS.SCOPE_SERVICE}create_site_visit/`, data),
  updateSiteVisit: data => axios.put(`${PATHS.SCOPE_SERVICE}update_site_visit/${data.id}`, data),
  deleteSiteVisit: id => axios.delete(`${PATHS.SCOPE_SERVICE}delete_site_visit/${id}`),
  getAllScopeSiteVisit: scopeId => axios.get(`${PATHS.SCOPE_SERVICE}site_visit_get_all_by_id/${scopeId}`),
  createSomethingElse: data => axios.post(`${PATHS.SCOPE_SERVICE}create_scope_service/`, data),
  updateSomethingElse: data => axios.put(`${PATHS.SCOPE_SERVICE}update_scope_service/${data.id}`, data),
  deleteSomethingElse: id => axios.delete(`${PATHS.SCOPE_SERVICE}delete_scope_service/${id}`),
  getAllScopeSomethingElse: scopeId => axios.get(`${PATHS.SCOPE_SERVICE}scope_service_get_all_by_id/${scopeId}`),
  createHumanSourceEnquiry: data => axios.post(`${PATHS.SCOPE_SERVICE}create_hs_enquiry/`, data),
  updateHumanSourceEnquiry: data => axios.put(`${PATHS.SCOPE_SERVICE}update_hs_enquiry/${data.id}`, data),
  deleteHumanSourceEnquiry: id => axios.delete(`${PATHS.SCOPE_SERVICE}delete_hs_enquiry/${id}`),
  getAllScopeHumanSourceEnquiry: scopeId => axios.get(`${PATHS.SCOPE_SERVICE}get_hs_enquiry_by_id/${scopeId}`),
  createDocumentRetrieval: data => axios.post(`${PATHS.SCOPE_SERVICE}create_doc_retrieval/`, data),
  updateDocumentRetrieval: data => axios.put(`${PATHS.SCOPE_SERVICE}update_doc_retrieval/${data.id}`, data),
  deleteDocumentRetrieval: id => axios.delete(`${PATHS.SCOPE_SERVICE}delete_doc_retrieval/${id}`),
  getAllScopeDocumentRetrieval: scopeId => axios.get(`${PATHS.SCOPE_SERVICE}get_doc_retrieval_by_id/${scopeId}`),
  getScopeSubject: id => axios.get(`${PATHS.SCOPE_SERVICE}${id}/subjects`),
  createScopeSubject: (id, data) => axios.post(`${PATHS.SCOPE_SERVICE}${id}/subjects/create`, data),
  deleteScopeSubject: (id, subjectId) => axios.delete(`${PATHS.SCOPE_SERVICE}${id}/subjects/${subjectId}/delete`)
};
export const tipalti = {
  getTipaltiUrls: () => axios.get('/billing/tipalti/iframe')
};
export const flags = {
  get: id => axios.get(`v2/projects/${id}/flags/`),
  create: (id, data) => axios.post(`v2/projects/${id}/flags/`, data)
};
export const jurisdictions = {
  list: () => axios.get('projects/jurisdiction')
};
export const billing = {
  getProjectAccounting: projectId => axios.get(`billing/accounting/${projectId}`),
  getProjectPriceChanges: projectId => axios.get(`billing/pricing/${projectId}`),
  getAdjustments: projectId => axios.get(`billing/adjustments/${projectId}`),
  createAdjustment: (projectId, data) => axios.post(`billing/adjustments/${projectId}`, data)
};
export const subjectAliases = {
  getSubjectAliases: projectId => axios.get(`v2/projects/${projectId}/subjects/aliases/`),
  createSubjectAlias: (subjectId, alias) => axios.post(`v2/projects/subjects/${subjectId}/aliases/`, {
    name: alias
  }),
  deleteSubjectAlias: (subjectId, aliasId) => axios.delete(`v2/projects/subjects/${subjectId}/aliases/${aliasId}`)
};
export const quality = {
  getMetabaseDashboardUrl: dashboardId => axios.get(`quality/metabase/${dashboardId}`),
  getUnmappedProjects: () => axios.get(`quality/unmappedprojects`),
  updateProjectTypes: update => axios.post(`quality/projecttypes`, update),
  getProjectsWithReport: limit => axios.get(`quality/projectswithreports?limit=${limit}`),
  runReportQA: (projectId, reportId, prompt, max_tokens, temperature, top_p) => {
    const data = {
      report_id: reportId
    };
    if (prompt) data['prompt'] = prompt;
    if (max_tokens) data['max_tokens'] = max_tokens;
    if (temperature) data['temperature'] = temperature;
    if (top_p) data['top_p'] = top_p;
    return axios.post(`quality/reportqa/${projectId}`, data);
  },
  getReportQAConfig: () => axios.get(`quality/reportqa/config`),
  createReportQAConfig: data => axios.post(`quality/reportqa/config`, data)
};
export const carlos = {
  requestMatch: (projectId, questionType) => axios.post(`carlos/hints/${projectId}`, {
    question_type: questionType
  }),
  getMatchQueue: () => axios.get(`carlos/hints`),
  getProjectHints: (projectId, questionType) => axios.get(`carlos/hints/${projectId}/${questionType}`)
};